import React, { useState, useEffect } from 'react'
import './App.css'

import { RotateIcon } from '../icons/RotateIcon'
import { DeleteIcon } from '../icons/DeleteIcon'
import { EnterIcon } from '../icons/EnterIcon'

import moment from 'moment'

import { fetchGame, gameKey, IGame } from '../../lib/fetchGame'
import { useInterval } from '../../hooks/useInterval'

function App() {
  const date = (() => {
    const path = window.location.pathname.slice(1)
    return parseInt(path) || 0
  })()
  const [game, setGame] = useState<IGame>({
    letters: [],
    words: [],
    center: '',
    geniusPoints: 0,
  })
  const [flashState, setFlashState] = useState('')
  const [input, setInput] = useState('')
  const [found, setFound] = useState<string[]>([])
  const [points, setPoints] = useState(0)
  const [elapsed, setElapsed] = useState(0)
  const [error, setError] = useState(null)

  useEffect(() => {
    const fetchData = async () => {
      let data: IGame
      try {
        data = await fetchGame(date)
      } catch (err) {
        setError(err)
        return
      }
      const localData = localStorage.getItem(gameKey(date))
      if (localData) {
        const jsonData = JSON.parse(localData) as {
          game: IGame;
          points: number;
          found: string[];
          date: Date;
          elapsed: number;
        }
        setFound(jsonData.found || [])
        setPoints(jsonData.points || 0)
        setElapsed(jsonData.elapsed || 0)
      }
      setGame(data)
    }
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useInterval(() => {
    setElapsed(elapsed + 1000)
  }, 1000)

  useEffect(() => {
    if (game && game.letters.length) {
      localStorage.setItem(
        gameKey(date),
        JSON.stringify({
          game,
          points,
          found,
          date,
          elapsed,
        })
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [game, found, points, elapsed])

  const handleSubmit = (e: React.FormEvent | React.MouseEvent | React.KeyboardEvent) => {
    e.preventDefault && e.preventDefault()
    if (found.includes(input)) {
      setFlashState('yellow')
    } else if (words.includes(input)) {
      let newPoints = points + input.length - 3
      if (isPangram(input, letters)) {
        newPoints += 7
      }
      setPoints(newPoints)
      setFound([...found, input])
      setFlashState('green')
    } else {
      setFlashState('red')
    }
    setInput('')
    setTimeout(() => {
      setFlashState('')
    }, 750)
  }

  const handleDelete = () => {
    setInput(input.slice(0, input.length - 1))
  }

  const createOnPress = (l: string) => {
    return (e: React.MouseEvent) => {
      e.preventDefault()
      setInput(`${input}${l.toLowerCase()}`)
    }
  }

  const handleKeyPress = (e: React.KeyboardEvent) => {
    const key = e.key.toLowerCase()
    if (key.length === 1 && key >= 'a' && key <= 'z') {
      setInput(`${input}${key}`)
      return
    }
    if (key === 'enter') {
      handleSubmit(e)
      return
    }
    if (key === 'backspace') {
      handleDelete()
      return
    }
  }

  const { letters, words, center, geniusPoints } = game
  const otherLetters = letters.filter((l) => l !== center)
  const duration = moment.duration(elapsed)

  const shuffleLetters = () => {
    setGame({
      ...game,
      letters: letters.sort(() => Math.round(Math.random()) - 1),
    })
  }

  if (error) {
    return (
      <div className="App">
        <h3>
          Sorry, that date is unavailable.
        </h3>
      </div>
    )
  }

  return (
    <div
      className="App"
      onKeyDownCapture={handleKeyPress}
      tabIndex={0}
    >
      <div
        className="App-genius_bar-progress"
        style={{
          width: `${geniusPoints ? (points / geniusPoints) * 100 : 0}vw`,
        }}
      />
      <div className="App-points">
        {points || '-'} |{' '}
        {`${duration.minutes()}:${duration
          .seconds()
          .toString()
          .padStart(2, '0')}`}
      </div>
      <div className="App-game">
        <div className="App-letters_container">
          <div className="App-inner_circle">
            <div className="App-letter center" onClick={createOnPress(center)}>
              {center}
            </div>
          </div>
          <div className="App-outer_circle" />
          <div className="App-rotate_icon" onClick={shuffleLetters}>
            <RotateIcon />
          </div>
          {otherLetters.map((l, i) => (
            <div
              key={l}
              className={`App-letter App-letter-${i}`}
              onClick={createOnPress(l)}
            >
              {l}
            </div>
          ))}
        </div>
      </div>
      <div className={`App-input_container ${flashState}`}>
        <div className="App-input">
          {input.split('').map((l, i) => {
            return (
              <span
                key={i}
                className={`App-input_letter ${
                  letters.includes(l) && 'valid'
                  } ${l === center && 'center'}`}
              >
                {l}
              </span>
            )
          })}
        </div>
        {input && (
          <div className="App-input-mobile">
            <div onClick={handleDelete}>
              <DeleteIcon className="App-input_delete_icon" />
            </div>
            <div onClick={handleSubmit}>
              <EnterIcon className="App-input_enter_icon" />
            </div>
          </div>
        )}
      </div>
      <div className="App-found_list">
        {found.sort().map((f) => (
          <div
            key={f}
            className={`App-found_word ${isPangram(f, letters) && 'pangram'}`}
          >
            {f.split('').map((l, i) => (
              <span
                key={i}
                className={`App-found_word-letter ${l === center && 'center'}`}
              >
                {l}
              </span>
            ))}
          </div>
        ))}
      </div>
    </div>
  )
}

function isPangram(word: string, letters: string[]) {
  return letters.every((l) => word.includes(l))
}


export default App
