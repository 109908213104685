export interface IGame {
  letters: string[];
  words: string[];
  center: string;
  geniusPoints: number;
}

export function gameKey(variation: number): string {
  return variation.toString()
}

export async function fetchGame(variation: number): Promise<IGame> {
  /* const url = `${BASE_URL}/Bee_${gameKey(date)}.html`
  const res = await fetch(API_PASSTHROUGH, {
    method: 'POST',
    body: JSON.stringify({ url }),
  }).then((res) => res.text())
  const doc = parser.parseFromString(res, 'text/html')

  const wordNodes = doc
    .querySelector('.answer-list .column-list')!
    .querySelectorAll('li')
  const words = Array.from(wordNodes)
    .filter((n) => !!n.textContent)
    .map((n) => n.textContent!.trim())
  const letters = Array.from(
    words.reduce((ret, word) => {
      word?.split('').forEach((l) => {
        ret.add(l)
      })
      return ret
    }, new Set<string>())
  )
  const puzzleNotes = Array.from(doc.querySelectorAll('h3')).filter(
    (n) => n.textContent && n.textContent.startsWith('Points Needed for Genius')
  )
  const geniusPoints = parseInt(
    (puzzleNotes[puzzleNotes.length - 1].textContent?.split(': ') || '').slice(
      1
    )[0],
    10
  )

  return {
    letters,
    words,
    center: findCenter(words, letters),
    geniusPoints,
  } */
  if (variation === 1) {
    return {
      letters: 'virtual'.split(''),
      words: ['virtual', 'ritual', 'rival', 'trail', 'trial', 'ultra', 'urial', 'vault', 'viral', 'virtu', 'vital', 'airt', 'alit', 'aril', 'lair', 'lari', 'lati', 'latu', 'liar', 'lira', 'litu', 'rail', 'rial', 'tail', 'tali', 'tirl', 'ulva', 'vail', 'vair', 'vatu', 'vial', 'virl', 'vita'],
      center: 'a',
      geniusPoints: 40,
    }
  }
  return {
    letters: 'reality'.split(''),
    words: ['irately', 'reality', 'tearily', 'aerily', 'artily', 'elytra', 'lyrate', 'realty', 'retail', 'retial', 'tailer', 'alert', 'alter', 'ariel', 'artel', 'early', 'irate', 'lairy', 'laity', 'later', 'layer', 'leary', 'liter', 'litre', 'lyart', 'ratel', 'relay', 'relit', 'retia', 'riley', 'riyal', 'taler', 'teary', 'telia', 'terai', 'tiler', 'trail', 'trial', 'aery', 'airt', 'airy', 'alit', 'aril', 'arty', 'aryl', 'earl', 'eyra', 'ilea', 'lair', 'lari', 'late', 'lati', 'lear', 'liar', 'lier', 'lira', 'lire', 'lite', 'lyre', 'rail', 'rale', 'rate', 'real', 'rely', 'rial', 'riel', 'rile', 'rite', 'tael', 'tail', 'tale', 'tali', 'tare', 'teal', 'tear', 'tela', 'tier', 'tile', 'tire', 'tirl', 'tray', 'trey', 'tyer', 'tyre', 'yare', 'year', 'yeti'],
    center: 'a',
    geniusPoints: 120,
  }
}
